
export default function PleaseLoginModal() {
  return (
    <div>
      <h1 className="text-2xl marcellus">
        Please log in or sign up to create a wishlist!
      </h1>

      {/* <div className=" flex justify-center mt-10" >
        <LoginModal />
        <SignUpModal />
      </div> */}
    </div>
  );
}
